import styled from "@emotion/styled"
import React from "react"

import colorPalette from "../colorPalette"
import hexiesColorsUrls from "../hexiesColorsUrls"
import mq from "../utils/mediaQuery"

const Container = styled.div`
  ${mq({
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "white",
    margin: ["16px", "16px", "16px"],
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  })};
`

const ColorContainer = styled.div`
  ${mq({
    display: "flex",
    width: "100%",
    flex: "1",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
  })};
`

const ColorSquare = styled.div`
  ${mq({
    width: "85%",
    paddingTop: "85%",
    backgroundColor: "#49F9F9",
    borderRadius: "10px",
    marginTop: ["16px", "22px", "22px"],
  })};
`

const HexieContainer = styled.div`
  ${mq({
    display: "flex",
    flexShrink: "0",
    backgroundColor: "#F5f5f5",
    height: ["224px", "386px", "386px"],
    width: ["140px", "230px", "230px"],
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    margin: ["8px", "16px", "16px"],
  })};
`

const NameSection = styled.h1`
  ${mq({
    width: "100%",
    fontSize: ["12px", "20px", "20px"],
    marginTop: ["08px", "16px", "16px"],
    marginBottom: ["0px", "0px", "0px"],
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "#CDCDCD",
  })};
`

const SubtitleSection = styled.h1`
  ${mq({
    width: "100%",
    fontSize: ["13px", "20px", "20px"],
    marginTop: ["06px", "12px", "12px"],
    marginBottom: ["0px", "0px", "0px"],
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "#CDCDCD",
  })};
`

const TitleSection = styled.h1`
  ${mq({
    width: "100%",
    fontSize: ["14px", "22px", "22px"],
    marginTop: ["0px", "00px", "00px"],
    marginBottom: ["08px", "16px", "16px"],
    textAlign: "center",
    fontFamily: "Montserrat",
    fontWeight: "700",
    color: "#969696",
  })};
`

const A = styled.a`
  ${mq({
    textDecoration: "none",
  })};
`

export default props => {
  const { colorNumber } = props
  const url =
    hexiesColorsUrls[colorNumber] ??
    "https://opensea.io/collection/cryptohexies"
  const hexString = colorNumber.toString(16).toUpperCase().padStart(2, "0")
  const cssString = colorPalette[colorNumber]
  return (
    <A href={url}>
      <HexieContainer>
        <ColorContainer>
          <ColorSquare style={{ backgroundColor: cssString }} />
          <NameSection style={{ color: cssString }}>0x{hexString}</NameSection>
          <SubtitleSection>{colorNumber + 1} of 256</SubtitleSection>
        </ColorContainer>
        <TitleSection>CRYPTOHEXIES</TitleSection>
      </HexieContainer>
    </A>
  )
}
