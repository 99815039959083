export default {
  0x0d: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284729853781475329",
  0x30: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284733152316358657",
  0x15: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284719958176825345",
  0x18: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284735351339614209",
  0x66: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284730953293103105",
  0x67: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284721057688453121",
  0x6a: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284727654758219777",
  0x8c: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284726555246592001",
  0x90: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284728754269847553",
  0xad: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284723256711708673",
  0xb2: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284717759153569793",
  0xb9: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284725455734964225",
  0xc2: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284724356223336449",
  0xd2: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284718858665197569",
  0xe7: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284722157200080897",
  0xfc: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/7132638817767921076418389309704660115599087066932466589354284732052804730881",
}
